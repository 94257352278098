<template>
	<div></div>
</template>

<script>
import _ from 'lodash';
import querystring from 'querystring';
import axios from 'axios';
import moment from 'moment';

// empty check
// const isEmpty = function (obj) {
// 	return obj == null || obj == undefined;
// };

// blank check
const isBlank = function (str) {
	if (typeof str !== 'string') {
		return !str;
	}
	return !str || str.trim().length === 0;
};

const kendoGridColumnTemplate = (function () {
	var self = {};

	self.hyphenPhone = function (field) {
		var closure = {
			field: field,
		};
		var template = function (dataItem) {
			var value = dataItem[closure.field] || '';
			if (isBlank(value)) {
				return value;
			}
			return value.replace(/(\d{2,3})(\d{3,4})(\d{4})/, '$1-$2-$3');
		};
		return template;
	};
	/**
	 *천 단위로 반점을 찍음. 1000 -> 1,000
	 */
	self.numberFormat = function (field) {
		var closure = {
			field: field,
		};
		var template = function (dataItem) {
			var value = dataItem[closure.field] || '';
			if (isBlank(value)) {
				return value;
			}
			if (value != null) {
				if (typeof value != 'string') {
					value = (value + '').split('');
				}
				let lengthOfValue = value.length;
				if (lengthOfValue <= 3) {
					return value.join('');
				}
				for (let i = lengthOfValue - 3; i > 0; i = i - 3) {
					value.splice(i, 0, ',');
				}
				return value.join('');
			}
			return '';
		};
		return template;
	};

	self.hyphenBzno = function (field) {
		var closure = {
			field: field,
		};
		var template = function (dataItem) {
			var value = dataItem[closure.field] || '';
			if (isBlank(value)) {
				return value;
			}
			return value.replace(/(\d{3})(\d{2})(\d{1,5})/, '$1-$2-$3');
		};
		return template;
	};

	/**
	 * Moment Template. (기본형식: 'YYYY-MM-DD HH:mm:ss')
	 *   사용방법 ==> template: kendoGridColumnTemplate.moment('regDate')
	 *              template: kendoGridColumnTemplate.moment('regDate', 'YYYYMMDDhhmmss')
	 */
	self.moment = function (field, momentFormatString, momentParseFormatString) {
		var closure = {
			field: field,
			momentFormatString: momentFormatString || 'YYYY-MM-DD HH:mm:ss',
			momentParseFormatString: momentParseFormatString,
		};
		var template = function (dataItem) {
			var value = dataItem[closure.field] || '';
			if (isBlank(value)) {
				return value;
			}
			return moment(value, closure.momentParseFormatString).format(closure.momentFormatString);
		};
		return template;
	};

	/**
	 * Moment Template. (기본형식: 'YYYY-MM-DD')
	 *   사용방법 ==> template: kendoGridColumnTemplate.momentISODate('regDate')
	 */
	self.momentISODate = function (field) {
		return self.moment(field, 'YYYY-MM-DD');
	};

	/**
	 * Boolean field Template. (값종류: Y/N/'')
	 *   사용방법 ==> template: kendoGridColumnTemplate.booleanYN('approved')
	 */
	self.booleanYN = function (field) {
		var closure = { field: field };
		return function (dataItem) {
			var value = dataItem[closure.field] || '';
			return _.contains(['true', 't', 'y', '1', true, 1], value) ? 'Y' : _.contains(['false', 'f', 'n', '0', false, 0], value) ? 'N' : value;
		};
	};

	/**
	 * Boolean field Template. (기본형식: 체크박스(checked/unchecked))
	 *   사용방법 ==> template: kendoGridColumnTemplate.checkbox('approved')
	 */
	self.checkbox = function (field) {
		var closure = { field: field };
		var template = function (dataItem) {
			var value = dataItem[closure.field] || '';
			var checked = _.contains(['true', 't', 'y', '1', true, 1], value) ? 'checked' : '';
			return `<input type="checkbox" disabled="true" ${checked} style="-ms-transform: scale(1.5); /* IE */  -moz-transform: scale(1.5); /* FF */  -webkit-transform: scale(1.5); /* Safari and Chrome */  -o-transform: scale(1.5); /* Opera */  padding: 10px;" />`;
		};
		return template;
	};

	/**
	 * field OptionMap Template.
	 * optionMap객체 : ex) [{text:'사용', value:false}, {text:'미사용', value:true}]
	 *   사용방법 ==> template: kendoGridColumnTemplate.optionMap('approved', optionMap)
	 *   사용방법 ==> template: kendoGridColumnTemplate.optionMap('approved', optionMap, (text, value) => text+"-"+value)
	 */
	self.optionMap = function (field, optionMap, renderFn) {
		var closure = {
			field: field,
			optionMap: optionMap || {},
			renderFn: renderFn,
		};
		return function (dataItem) {
			var value = dataItem[closure.field];
			if (_.isUndefined(value)) {
				value = '';
			}

			var option = _.find(closure.optionMap, { value: value });
			if (!_.isObject(option)) {
				// '1' 과 1 경우 찾지 못함, 보강처리 1을 문자열로 변경 후 한번더 조회
				if (_.isNumber(value)) {
					option = _.find(closure.optionMap, { value: value.toString() });
					if (!_.isObject(option)) {
						return value;
					}
					// 값이 null 인 경우 공백처리
				} else if (value == null) {
					return '';
				} else {
					return value;
				}
			}
			var text = option.text;
			if (_.isFunction(renderFn)) {
				try {
					return renderFn(text, value);
				} catch (e) {
					console.log('kendoGridColumnTemplate.optionMap renderFn 오류: ', e);
				}
			}
			return text;
		};
	};

	/**
	 * field OptionMap Template.
	 * optionMap객체 : ex) [{text:'사용', value:false}, {text:'미사용', value:true}]
	 *   사용방법 ==> template: kendoGridColumnTemplate.optionMap('approved', optionMap)
	 *   사용방법 ==> template: kendoGridColumnTemplate.optionMap('approved', optionMap, (text, value) => text+"-"+value)
	 */
	self.optionMapBoolean = function (field, optionMap, renderFn) {
		var closure = {
			field: field,
			optionMap: optionMap || {},
			renderFn: renderFn,
		};
		return function (dataItem) {
			var value = dataItem[closure.field];
			if (_.isUndefined(value)) {
				value = '';
			}

			var option = _.findWhere(closure.optionMap, { value: value });
			if (!_.isObject(option)) {
				// '1' 과 1 경우 찾지 못함, 보강처리 1을 문자열로 변경 후 한번더 조회
				if (_.isNumber(value)) {
					option = _.findWhere(closure.optionMap, { value: value.toString() });
					if (!_.isObject(option)) {
						return value;
					}
					// 값이 null 인 경우 공백처리
				} else if (value == null) {
					return '';
				} else {
					return value;
				}
			}
			var text = option.text;
			if (_.isFunction(renderFn)) {
				try {
					return renderFn(text, value);
				} catch (e) {
					console.log('kendoGridColumnTemplate.optionMap renderFn 오류: ', e);
				}
			}
			if (option.value) {
				text = "<font color='blue'>" + text + '</font>';
			} else if (!option.value) {
				text = "<font color='red'>" + text + '</font>';
			}

			return text;
		};
	};

	/**
	 * field CodeMap Template.
	 * codeMap객체 : ex) {"NEW":"신규","CLOSED":"정지","ACTIVATED":"활성"}
	 *   사용방법 ==> template: kendoGridColumnTemplate.codeMap('approved', codeMapObj)
	 *   사용방법 ==> template: kendoGridColumnTemplate.codeMap('approved', codeMapObj, function(text, value) { return text + "-" + value; })
	 */
	self.codeMap = function (field, codeMap, renderFn) {
		var closure = {
			field: field,
			codeMap: codeMap || {},
			renderFn: renderFn,
		};
		return function (dataItem) {
			var value = dataItem[closure.field] || '';
			var text = closure.codeMap[value];
			if (_.isEmpty(text)) {
				return value;
			}
			if (_.isFunction(renderFn)) {
				try {
					return renderFn(text, value);
				} catch (e) {
					console.log('kendoGridColumnTemplate.codeMap renderFn 오류: ', e);
				}
			}
			return text;
		};
	};

	self.TFCodeMap = function (field, trueMsg, falseMsg) {
		var closure = {
			field: field,
			trueMsg: trueMsg || 'true',
			falseMsg: falseMsg || 'false',
		};
		var template = function (dataItem) {
			var textValue = dataItem[closure.field];
			if (typeof textValue == 'string') {
				if (textValue.toLowerCase() == 'true') {
					return "<font color='blue'>" + closure.trueMsg + '</font>';
				} else if (textValue.toLowerCase() == 'false') {
					return "<font color='red'>" + closure.falseMsg + '</font>';
				}
				return textValue + '1';
			} else {
				if (textValue) {
					return "<font color='blue'>" + closure.trueMsg + '</font>';
				} else if (!textValue) {
					return "<font color='red'>" + closure.falseMsg + '</font>';
				}
			}
			return textValue + '2';
		};
		return template;
	};

	self.dDayCheck = function (field) {
		var closure = {
			field: field,
		};
		return function (dataItem) {
			var value = dataItem[closure.field];

			if (isBlank(value)) {
				return value;
			}

			if (value < 0) return "<font color='red'>" + value + '</font>';
			else return value;
		};
	};

	return self;
	// eslint-disable-next-line prettier/prettier
}());
export default {
	data: () => ({
		kendoDataSource: null,
		kendoGrid: null,
		selectedRowItem: null,
		sort: null,
		preSearchCondition: {},
	}),
	watch: {
		selectedRowItem: function (newValue) {
			this.$emit('selected-row-item-changed', newValue);
		},
		sort: function (newValue) {
			this.$emit('sort-changed', newValue);
		},
		kendoDataSource: function (newValue) {
			this.$emit('data-source-changed', newValue);
		},
	},
	//컴포넌트 속성
	props: {
		//그리드데이터 API URL. ex: "/admin/api/test/page.json"
		apiUrl: { required: true, type: String },
		//그리드데이터 검색조건 적용함수
		applySearchCondition: {
			required: false,
			type: Function,
			default: function (param) {
				return param;
			},
		},
		//API응답 Dispatch 함수
		apiFail: {
			required: false,
			type: Function,
			// eslint-disable-next-line no-unused-vars
			default: function (response, /*String*/ textStatus, /*String*/ errorThrown) {
				var responseJSON = response.responseJSON || {};
				function format(label, fieldName) {
					return _.has(responseJSON, fieldName) ? '\n - ' + label + ': ' + responseJSON[fieldName] : '';
				}

				console.log('=========> response :', response);
				console.log('=========> response.status :', response.status);

				if (response && response.status == 401) {
					_.defer(function () {
						//로그인필요
						if (confirm('세션이 만료되었습니다. 로그인페이지로 이동하시겠습니까?')) {
							// document.location.href = '/login.do';
							document.location.href = '/login';
						}
					});
					return;
				}
				//Spring 표준 Error: timestamp, status, error, message, path
				//TGrid Api Error: timestamp, status, errorCode, message, debugMessage, subErrors
				// eslint-disable-next-line no-unused-vars
				var errorDetails =
					format('상태', 'status') + format('에러코드', 'errorCode') + format('에러', 'error') + format('메시지', 'message') + format('경로', 'path');
				console.warn('API 호출예외 발생', responseJSON);

				if (_.has(responseJSON, 'verbose')) {
					if (!isBlank(responseJSON['verbose'])) {
						errorDetails = errorDetails + format('verbose메세지', 'verbose');
					}
				}

				// alert("API 호출예외 발생\n" + errorDetails);
			},
		},
		//그리드 데이터 초기화시 자동 로드
		autoBind: { required: false, type: Boolean, default: false },
		//그리드 Column 설정
		columns: {
			required: false,
			type: Array,
			default: function () {
				return [];
			},
		},
		//허용가능한 PageSize목록
		allowPageSizeList: {
			required: false,
			type: Array,
			default: function () {
				return [5, 10, 20, 30, 50, 100, 200];
			},
		},
		height: { required: false, type: Number, default: 425 },
		pageSize: { required: false, type: Number, default: 20 },
	},
	//컴포넌트 메소드
	methods: {
		//데이터 로드
		load: function () {
			this.$data.kendoDataSource.read();
		},
		clear: function () {
			this.$data.kendoDataSource.data([]);
		},
		total: function () {
			let grid = $(this.$el).data('kendoGrid');
			let dataSource = grid.dataSource;
			return dataSource.total();
		},
		//UI refresh
		refresh: function () {
			// this.$data.kendoDataSource.read();
			//var grid = this.$data.kendoGrid.data("kendoGrid");
			var grid = $(this.$el).data('kendoGrid');
			console.log('===>', grid);

			grid.refresh();
		},
		compareSearchOptions: function (options) {
			// 검색조건이 달라졌는지 확인
			// 이전 검색조건과 같으면 true / 다르면 false return
			let preKeys = Object.keys(this.preSearchCondition);
			let newKeys = Object.keys(options);

			let result = true;

			if (preKeys) {
				for (let i = 0; i < newKeys.length; i++) {
					let key = newKeys[i];

					if (options[key] && typeof options[key] == 'object' && options[key].length == this.preSearchCondition[key].length) continue;

					if (options[key] != this.preSearchCondition[key]) {
						result = false;
						this.preSearchCondition = options;
					}
				}
			}

			return result;
		},
	},
	//컴포넌트 lifecycle Event
	mounted: function () {
		var vm = this;

		//Kendo Data Source
		var kendoDataSourceOption = {
			transport: {
				read: function (options) {
					var data = options.data;
					var param = _.extend({}, { page: data.page - 1, size: data.pageSize });

					// 검색조건 변화 확인
					let optionChange = vm.compareSearchOptions(vm.applySearchCondition());

					//검색조건 적용
					param = vm.applySearchCondition(param);

					//Sort조건 적용
					if (data.sort) {
						var sortValues = _.map(data.sort, item => item.field + ',' + item.dir);
						if (!_.isEmpty(sortValues)) {
							param = _.extend(param, { sort: sortValues });
						}
					}

					//Row선택해제
					vm.selectedRowItem = null;
					//Sort상태저장
					vm.sort = data.sort;

					let sendParam = querystring.stringify(param);

					axios
						.post(vm.apiUrl, sendParam)
						.then(response => {
							let rData = response.data;

							// 순서자동생성
							let startRownum = rData.totalElements - rData.number * rData.size;
							// eslint-disable-next-line no-unused-vars
							_.each(rData.content, function (item) {
								item = $.extend(true /*deep*/, item, { autoNumber: startRownum-- });
							});

							// notify the data source that the request succeeded
							options.success(rData);

							// 검색조건 변경 시 1번 페이지 표시
							if (!optionChange && $(vm.$el).data('kendoGrid').dataSource.page() != 1) {
								$(vm.$el).data('kendoGrid').dataSource.page(1);
							}
						})
						.catch(function (jqXHR, /*String*/ textStatus, /*String*/ errorThrown) {
							console.error(
								vm.apiUrl,
								'error=> textStatus: ' + textStatus + ', errorThrown: ' + errorThrown + ', responseText: ' + jqXHR.responseText,
							);
							vm.apiFail(jqXHR, textStatus, errorThrown);
							// notify the data source that the request failed
							options.error(jqXHR);
						});
				},
			},
			schema: {
				parse: function (response) {
					var parsedData = {
						data: response.content,
						total: response.totalElements,
					};
					return parsedData;
				},
				data: function (parsedData) {
					return parsedData.data;
				},
				total: function (parsedData) {
					return parsedData.total;
				},
			},
			pageSize: this.pageSize,
			serverPaging: true,
			serverSorting: true,
		};

		// eslint-disable-next-line no-undef
		this.$data.kendoDataSource = new kendo.data.DataSource(kendoDataSourceOption);

		var columnConfigMapper = function (item) {
			if (_.has(item, 'columns')) {
				//Multi-Column header
				_(item.columns).map(columnConfigMapper);
			}
			//Simple Column header
			if (!_.has(item, 'width')) {
				_.set(item, 'width', '100px');
			} //컬럼 width 기본값
			if (!_.has(item, 'minResizableWidth')) {
				_.set(item, 'minResizableWidth', 60);
			} //컬럼 리사이즈시 최소값 기본값
			if (_.has(item, 'align')) {
				_.set(item, 'attributes', { class: 'text-' + item.align });
			} //[간편설정] 컬럼 텍스트정렬 적용
			if (_.has(item, 'dataFormat')) {
				if (item.dataFormat == 'YYYY-MM-DD') _.set(item, 'template', kendoGridColumnTemplate.moment(item.field, item.dataFormat));
				if (item.dataFormat == 'YYYY-MM-DD HH:mm:ss') _.set(item, 'template', kendoGridColumnTemplate.moment(item.field, item.dataFormat));
				if (item.dataFormat == 'dDayCheck') _.set(item, 'template', kendoGridColumnTemplate.dDayCheck(item.field));
				if (item.dataFormat == 'optionMap') {
					_.set(item, 'template', kendoGridColumnTemplate.optionMap(item.field, item.mapData));
					_.unset(item, 'mapData');
				}
				if (item.dataFormat == 'hyphenPhone') _.set(item, 'template', kendoGridColumnTemplate.hyphenPhone(item.field));
				if (item.dataFormat == 'hyphenBzno') _.set(item, 'template', kendoGridColumnTemplate.hyphenBzno(item.field));
				if (item.dataFormat == 'numberFormat') _.set(item, 'template', kendoGridColumnTemplate.numberFormat(item.field));
				_.unset(item, 'dataFormat');
			} // 데이터 포맷 설정 템플릿 적용
			if (_.has(item, 'overFlowHidden')) {
				if (item.overFlowHidden == true) {
					_.set(item, 'attributes', { style: 'text-overflow: ellipsis; overflow: hidden; white-space: nowrap' });
				}
			}
			_.set(item, 'headerAttributes', { style: 'text-align: center; vertical-align: middle; font-weight: bold' }); //헤더정렬 기본값
			return item;
		};

		//Kendo Grid
		var kendoGridOption = {
			autoBind: this.autoBind,
			dataSource: this.$data.kendoDataSource,
			height: this.height,
			sortable: true,
			pageable: {
				refresh: true,
				previousNext: true,
				buttonCount: 10,
				pageSize: this.pageSize, //this.pageSize,
				pageSizes: this.allowPageSizeList,
				alwaysVisible: true,
				messages: {
					itemsPerPage: '',
					display: '{0}-{1} of {2}',
				},
			},
			resizable: true,
			columns: this.columns.map(columnConfigMapper),
			noRecords: true,
			messages: {
				noRecords: '검색된 데이터가 없습니다.',
			},
			selectable: 'row',
			change: function (event) {
				vm.selectedRowItem = null; //같은 줄을 선택하더라도 selectedRowItem watch가 되도록 null설정
				vm.selectedRowItem = event.sender.dataItems()[this.select().index()];
			},
		};
		this.$data.kendoGrid = $(this.$el).kendoGrid(kendoGridOption);
	}, //mounted
	beforeDestroy: function () {
		var el = $(this.$el);
		el.data('kendoGrid').destroy();
		el.empty();
	},
};
</script>
